import { request } from './request'

/*
* 添加复制商品
*/
export function dataSave (data) {
  return request({
    url: '/api/alibaba/goods/save',
    method: 'post',
    data
  })
}

/**
 * 复制列表
 */
export function getLists (params) {
  return request({
    url: '/api/alibaba/goods/getLists',
    method: 'get',
    params
  })
}

/**
 * 根据ID复制商品
 */
export function deleteByid (params) {
  return request({
    url: '/api/alibaba/goods/deleteByid',
    method: 'get',
    params
  })
}

/**
 * 根据ID复制商品
 */
export function deleteAllByid (data) {
  return request({
    url: '/api/alibaba/goods/deleteAllByid',
    method: 'post',
    data
  })
}
