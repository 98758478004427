// workerman
export const ws = (ids) => {
  // 连接ws
  const wsServer = 'ws://39.98.72.47:2345'
  const websocket = new WebSocket(wsServer)
  websocket.onopen = function (evt) {
    console.log('服务器连接成功.')
    const data = JSON.stringify(ids)
    websocket.send(data)
  }
  // 返回数据
  websocket.onmessage = function (evt) {
    console.log('接收的服务器信息: ' + evt.data)
    websocket.close()
  }
  return { ws }
}
