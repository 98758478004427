import { request } from './request'

/*
* 获取相册列表
*/
export function listAlbum () {
  return request({
    url: '/api/alibaba.open.album'
  })
}
