<template>
  <el-container class="home-container">
    <el-aside class="el-aside rr-sidebar hidden-xs-only" style="height: 100%;background-color: #545c64">
        <Header :navActive='navActive'/>
    </el-aside>
    <el-container class="con-right">
      <el-main>
        <Breadcrumb/>

<el-breadcrumb separator-class="el-icon-arrow-right">
  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
  <el-breadcrumb-item>复制商品</el-breadcrumb-item>
  <el-breadcrumb-item>单品复制</el-breadcrumb-item>
</el-breadcrumb>

<!-- 内容 开始 -->
<el-card class="box-card mt20">

        <div class="demo-input-suffix">
          <div class="demo-input-title">宝贝链接：</div>
          <div class="" style="width: 550px;">
            <el-input
              type="textarea"
              :rows="6"
              placeholder="请输入内容"
              v-model="textarea">
            </el-input>
          </div>
        </div>
        <!-- 类目选择 开始 -->
        <div class="demo-input-suffix">
          <div class="demo-input-title box-align">类目选择：</div>
          <el-radio v-model="matching_id" label="1">自动匹配</el-radio>
          <el-radio v-model="matching_id" label="2">手动匹配</el-radio>
        </div>
        <!-- 类目选择 结束 -->
        <!-- 运费模版 开始 -->
        <div class="demo-input-suffix box-align">
          <div class="demo-input-title box-align">运费模版：</div>
            <el-select v-model="freightTemplateID" placeholder="请选择" @change="freightTemplateClick">
            <el-option
              v-for="item in freightTemplate"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              >
            </el-option>
          </el-select>

          <el-select v-model="freightTemplateListID" placeholder="请选择" class="ml10" v-show="freightTemplateShow">
            <el-option
              v-for="item in freightTemplateList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          <span class="ml10" v-show="freightTemplateShow"><el-button type="primary">新建</el-button></span>
          <el-tag type="danger" class="ml10">必选</el-tag>
        </div>
        <!-- 运费模版 结束 -->
        <!-- 图片目录 开始 -->
        <div class="demo-input-suffix box-align">
          <div class="demo-input-title box-align">图片目录：</div>
            <el-select v-model="albumtypeID" placeholder="请选择">
            <el-option
              v-for="item in albumtype"
              :key="item.albumID"
              :label="item.name"
              :value="item.albumID">
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: var(--el-text-color-secondary); font-size: 13px">{{ item.imageCount }}</span>
            </el-option>
          </el-select>
          <el-tag type="danger" class="ml10">必选</el-tag>
        </div>
        <!-- 图片目录 结束 -->
        <!-- 自定义分类 开始 -->
        <div class="demo-input-suffix box-align">
          <div class="demo-input-title box-align">自定义分类：</div>
            <el-select v-model="userCategorysID" multiple placeholder="请选择">
            <el-option
              v-for="group in userCategorys"
              :key="group.id"
              :label="group.name"
              :value="group.id">
              <el-option
                v-for="item in group.subCategoryList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-option>
          </el-select>
          <el-tag type="danger" class="ml10">必选</el-tag>
        </div>
        <!-- 发货地址 开始 -->
          <div class="demo-input-suffix box-align">
            <div class="demo-input-title box-align">发货地址：</div>
              <el-select v-model="sendGoodsAddressID" placeholder="请选择">
              <el-option
                v-for="item in sendGoodsAddress"
                :key="item.id"
                :label="item.fullAddress"
                :value="item.id">
              </el-option>
            </el-select>
            <el-tag type="danger" class="ml10">必选</el-tag>
          </div>
          <!-- 发货地址 结束 -->
        <!-- 自定义分类 结束 -->
        <!-- 复制方式 开始 -->
        <!-- <div class="demo-input-suffix">
          <div class="demo-input-title box-align">复制方式：</div>
          <el-radio v-model="radio" label="1">后台执行</el-radio>
          <el-radio v-model="radio" label="2">直接复制(推荐)</el-radio>
        </div> -->
        <!-- 复制方式 结束 -->
        <!-- 设置更多 开始 -->
        <div class="demo-input-suffix">
          <div class="demo-input-title box-align"></div>
          <el-button type="danger" @click="goTo()">设置更多</el-button>
        </div>
        <!-- 设置更多 结束 -->

        <div class="more" v-if="isShow">
          <!-- 标题优化 开始 -->
          <div class="demo-input-suffix box-align">
            <div class="demo-input-title box-align">标题优化：</div>
              <div class="f-input-title">替换关键字</div>
              <div class=""><el-input v-model="biatiyuan" placeholder="原关键字"></el-input></div>
              <div class="ft-input-title">替换成</div>
              <div class=""><el-input v-model="biatiti" placeholder="新关键字"></el-input></div>
          </div>
          <!-- 标题优化 结束 -->
          <!-- 标题优化 开始 -->
          <div class="demo-input-suffix box-align">
            <div class="demo-input-title box-align"></div>
              <div class="f-input-title">添加前后缀</div>
              <div class=""><el-input v-model="qianzeng" placeholder="前关键字"></el-input></div>
              <div class="ft-input-title">原标题</div>
              <div class=""><el-input v-model="houzeng" placeholder="后关键字"></el-input></div>
          </div>
          <!-- 标题优化 结束 -->
          <!-- 详情替换 -->
          <div class="demo-input-suffix">
          <div class="demo-input-title">详情替换</div>
          <div class="" style="width: 550px;">
            <el-input
              type="textarea"
              :rows="6"
              placeholder="请输入要替换的内容 格式为：针心:真心 多个可以按回车输入，冒号为英文格式"
              v-model="detail">
            </el-input>
          </div>
        </div>
          <!-- 价格设置 开始 -->
          <div class="demo-input-suffix box-align">
            <div class="demo-input-title box-align">价格设置：</div>
              <el-select v-model="jiageID" placeholder="请选择">
                <el-option
                  v-for="item in jiage"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            <div class="ft-input-title"><el-input v-model="jiage_num" placeholder="新关键字"></el-input></div>
          </div>
          <!-- 价格设置 结束 -->
          <!-- 库存优化 开始 -->
          <div class="demo-input-suffix box-align">
            <div class="demo-input-title box-align">库存优化：</div>
              <el-select v-model="kucunID" placeholder="请选择">
                <el-option
                  v-for="item in kucun"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            <div class="ft-input-title"><el-input v-model="kucun_num" placeholder="新关键字"></el-input></div>
          </div>
          <!-- 库存优化 结束 -->
          <!-- 连接设置 开始 -->
          <div class="demo-input-suffix box-align">
            <div class="demo-input-title box-align">连接设置：</div>
              <el-checkbox-group v-model="checkList">
                <el-checkbox v-for="city in cities" :label="city.value" :key="city.value" :checked="city.checked">{{city.label}}</el-checkbox>
              </el-checkbox-group>
                <!-- <el-checkbox-group v-model="checkList">
                  <el-checkbox label="去除外链" name="type" value="1"></el-checkbox>
                  <el-checkbox label="去重入库" name="type"></el-checkbox>
                  <el-checkbox label="强制去错" name="type"></el-checkbox>
                </el-checkbox-group> -->
          </div>
          <!-- 连接设置 结束 -->
          <!-- 复制图片 开始 -->
          <div class="demo-input-suffix">
            <div class="demo-input-title box-align">复制图片：</div>
              <el-select v-model="uploadImageID" placeholder="请选择">
              <el-option
                v-for="item in uploadImage"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <!-- 复制图片 结束 -->
          <!-- 上传显示到 开始 -->
          <div class="demo-input-suffix">
            <div class="demo-input-title box-align">上传显示到：</div>
              <el-select v-model="uploadToID" placeholder="请选择">
              <el-option
                v-for="item in uploadTo"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <!-- 上传显示到 结束 -->
          <!-- 发货时间 开始 -->
          <div class="demo-input-suffix">
            <div class="demo-input-title box-align">发货时间：</div>
              <el-select v-model="deliveryLimitID" placeholder="请选择">
              <el-option
                v-for="item in deliveryLimit"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <!-- 发货时间 结束 -->
          <!-- 起批量 开始 -->
          <div class="demo-input-suffix box-align">
            <div class="demo-input-title box-align">起批量：</div>
              <div class=""><el-input v-model="qipiliang" placeholder="数量"></el-input></div>
          </div>
          <!-- 起批量 结束 -->
          <!-- 单位重量 开始 -->
          <div class="demo-input-suffix box-align">
            <div class="demo-input-title box-align">单位重量：</div>
              <div class=""><el-input v-model="zhongliang" placeholder="数量"></el-input></div>
          </div>
          <!-- 单位重量 结束 -->
          <!-- 品牌替换 开始 -->
          <div class="demo-input-suffix box-align">
            <div class="demo-input-title box-align">品牌替换：</div>
              <div class=""><el-input v-model="pinpai" placeholder="商家要替换的品牌"></el-input></div>
          </div>
          <!-- 品牌替换 结束 -->
          <!-- 视频添加 开始 -->
          <div class="demo-input-suffix box-align">
            <div class="demo-input-title box-align">视频添加：</div>
              <div class=""><el-input v-model="video_url" placeholder="请输入视频中心中的视频地址"></el-input></div>
          </div>
          <!-- 视频添加 结束 -->
        </div>

        <!-- 提交 开始 -->
        <div class="demo-input-suffix box-align">
          <div class="demo-input-title box-align"></div>
          <el-button disabled @click="onSubmit" v-if="showBtn">正在同步，请稍等...</el-button>
          <el-button type="primary" @click="onSubmit" v-else>立即提交</el-button>
        </div>
        <!-- 提交 结束 -->
</el-card>
      <!-- 内容 结束 -->
    </el-main>
    </el-container>
</el-container>

</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import { listftm } from 'network/freightTemplate'
import { listAlbum } from 'network/album'
import { listuserCategory } from 'network/usercategory'
import { listsendGoodsAddress } from 'network/sendGoodsAddress'
import { dataSave } from 'network/goods'
import { ws } from 'utils/workerman'
import Header from 'components/common/header/Header'
import Breadcrumb from 'components/common/breadcrumb/Breadcrumb'

export default defineComponent({
  name: 'Fuzhi',
  components: {
    Header,
    Breadcrumb
  },
  setup () {
    const state = reactive({
      showBtn: false,
      isShow: false,
      freightTemplateShow: false,
      freightTemplate: [{
        value: '1',
        label: '使用运费模版'
      }, {
        value: '2',
        label: '表示运费说明'
      }, {
        value: '3',
        label: '卖家承担运费'
      }],
      cities: [{
        value: '1',
        label: '去除外链',
        checked: true
      }, {
        value: '2',
        label: '去重入库',
        checked: true
      }, {
        value: '3',
        label: '强制去错',
        checked: false
      }],
      jiage: [{
        value: '1',
        label: '加'
      }, {
        value: '2',
        label: '减'
      }, {
        value: '3',
        label: '乘'
      }, {
        value: '4',
        label: '除'
      }],
      kucun: [{
        value: '1',
        label: '加'
      }, {
        value: '2',
        label: '减'
      }, {
        value: '3',
        label: '乘'
      }, {
        value: '4',
        label: '除'
      }],
      uploadImage: [{
        value: '1',
        label: '是'
      }, {
        value: '2',
        label: '否'
      }],
      uploadTo: [{
        value: '1',
        label: '销售中的产品'
      }, {
        value: '2',
        label: '未上架的产品'
      }],
      deliveryLimit: [{
        value: '1',
        label: '3天'
      }, {
        value: '2',
        label: '5天'
      }, {
        value: '3',
        label: '7天'
      }, {
        value: '4',
        label: '10天'
      }, {
        value: '5',
        label: '15天'
      }],
      sendGoodsAddress: [],
      userCategorys: [],
      freightTemplateList: [],
      albumtype: []
    })
    const detail = reactive({
      textarea: '',
      matching_id: '1',
      freightTemplateID: '',
      freightTemplateListID: '',
      albumtypeID: '',
      userCategorysID: '',
      sendGoodsAddressID: '',
      biatiyuan: '',
      biatiti: '',
      qianzeng: '',
      houzeng: '',
      detail: '',
      jiageID: '',
      jiage_num: '',
      kucunID: '',
      kucun_num: '',
      checkList: [],
      uploadImageID: '1',
      uploadToID: '1',
      deliveryLimitID: '1',
      qipiliang: '0',
      zhongliang: '0.3',
      pinpai: '',
      video_url: ''
    })
    // 运费模版
    const freightTemplateClick = (tab) => {
      console.log(tab)
      if (tab === '1') {
        // console.log(tab)
        state.freightTemplateShow = !state.freightTemplateShow
      } else {
        state.freightTemplateShow = false
      }
    }
    // 设置更多
    const goTo = () => {
      state.isShow = !state.isShow
    }

    // 添加
    const onSubmit = () => {
      if (detail.textarea === '') {
        ElMessage.error('请输入宝贝链接')
        return false
      }
      if (detail.freightTemplateID === '') {
        ElMessage.error('请选择运费模版')
        return false
      }
      if (detail.albumtypeID === '') {
        ElMessage.error('请选择图片目录')
        return false
      }
      if (detail.sendGoodsAddressID === '') {
        ElMessage.error('请选择发货地址')
        return false
      }
      // 添加复制商品
      // const detail = JSON.parse(detail)

      state.showBtn = true
      dataSave(detail).then(res => {
        if (res.status === 1) {
          // 连接ws
          const obj = res.result
          ws(obj)
          state.showBtn = false
          ElMessage.success({
            message: '恭喜你，添加成功！',
            type: 'success'
          })
        } else {
          state.showBtn = false
        }
      })
    }

    onMounted(() => {
      // 运费模版
      listftm().then(res => {
        if (res.status === 1) {
          state.freightTemplateList = res.result
        } else {
          state.freightTemplateList = []
        }
      })
      // 图片目录
      listAlbum().then(res => {
        if (res.status === 1) {
          state.albumtype = res.result
        } else {
          state.albumtype = []
        }
      })
      // 获取授权用户的商品自定义分类列表
      listuserCategory().then(res => {
        if (res.status === 1) {
          state.userCategorys = res.result
          // console.log(res.result)
        } else {
          state.userCategorys = []
        }
      })
      // 我的发货地址列表
      listsendGoodsAddress().then(res => {
        if (res.status === 1) {
          state.sendGoodsAddress = res.result
          // console.log(res.result)
        } else {
          state.sendGoodsAddress = []
        }
      })
    })
    return {
      freightTemplateClick,
      goTo,
      onSubmit,
      ...toRefs(detail),
      ...toRefs(state),
      navActive: '2-1'
    }
  }
})
</script>
<style lang="scss" scoped>
  .demo-input-suffix {
    display: flex;
    font-size: 14px;
    margin-top: 20px;
  }
  .demo-input-title{
    width: 120px;
  }
  // .mt{
  //   margin-top: 20px;
  // }
  .f-input-title{
    margin-right: 10px;
  }
  .ft-input-title{
    margin-left: 10px;
    margin-right: 10px;
  }
</style>
